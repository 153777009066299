import img1 from '../Assets/img1.jpg';
import img2 from '../Assets/img2.jpg';
import img3 from '../Assets/img3.jpg';
import img4 from '../Assets/img4.jpg';
import img5 from '../Assets/img5.jpg';
import img6 from '../Assets/img6.jpg';
import img7 from '../Assets/img7.jpg';
import img8 from '../Assets/img8.jpg';
import img9 from '../Assets/img9.jpg';
import img10 from '../Assets/img10.jpg';
import img11 from '../Assets/img11.jpg';
import img12 from '../Assets/img12.jpg';
import img13 from '../Assets/img13.jpg';
import img14 from '../Assets/img14.jpg';
import img15 from '../Assets/img15.jpg';

export default [
  img1, img2, img3, img4, img5,
  img6, img7, img8, img9, img10, 
  img11, img12, img13, img14, img15,
  // img16, img17
];