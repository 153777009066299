import React from 'react';
import './App.css';
import { Landing } from './Modules/Landing/Landing';

function App() {
  return (
  <>
    <Landing/>
  </>
);
}

export default App;
